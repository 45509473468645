export class Layout {
    columns = [];
    rows = [];

    addColumns(columns) {
        this.columns = columns;
    }

    addRows(rows) {
        this.rows = rows;
    }

    getComponentPosition(itemNumber) {
        var x = (itemNumber < this.columns.length) ? this.columns[itemNumber] : this.columns[itemNumber % this.columns.length];
        var y = this.rows[Math.floor(itemNumber / this.columns.length)];
        return {x: x, y: y};
    }

}

export class FieldDescriptor {

    static  TYPE_TEXT = 'text';
    static  TYPE_COMMENT_FIELD = 'comment_field';
    static  TYPE_NUMBER = 'number';
    static  TYPE_BOOLEAN = 'checkbox';
    static  TYPE_DATE = 'date';
    static  TYPE_SELECT = 'select';
    static  TYPE_SELECT_SEARCH = 'select_search';
    static  TYPE_PASSWORD = 'password';
    static  TYPE_FILE_UPLOADER = "file_uploader";
    static  TYPE_LABEL = "label";
    static  TYPE_ACTION_BUTTON = "action_button";
    static  TYPE_TAX_NUMBER_FIELD = "tax_number_field";

    idField = false;
    type = null;
    name = null;
    title = null;
    defaultValue = null;

    endPoint = null;
    visibleTableField = true;
    visibleFormField = true;

    editableTableField = true;
    blockEditingIf = null;

    jump = 0; // Átugorja az adott számú komponens helyet.

    action = null;
    ref = null;

    commentFieldWidht = 100;
    commentFieldMaxTextLenght = -1;

    constructor(idField, type, name, title, defaultValue) {
        this.idField = idField;
        this.type = type;
        this.name = name;
        this.title = title;
        this.defaultValue = defaultValue;
    }

    setEndPoint(endPoint) {
        this.endPoint = endPoint;
    }
    getEndPoint(endPoint) {
        return this.endPoint;
    }

    isIDField() {
        return this.idField;
    }

    getType() {
        return this.type;
    }

    getName() {
        return this.name;
    }
    getTitle() {
        return this.title;
    }

    getDefaultValue() {
        return this.defaultValue;
    }

    setVisibleTableField(visible) {
        this.visibleTableField = visible;
    }

    isVisibleTableField() {
        return this.visibleTableField;
    }

    setVisibleFormField(visible) {
        this.visibleFormField = visible;
    }

    isVisibleFormField() {
        return this.visibleFormField;
    }

    setEditableTableField(editable) {
        this.editableTableField = editable;
    }

    isEditableTableField() {
        return this.editableTableField;
    }

    setJump(jump) {
        this.jump = jump;
    }

    getJump() {
        return this.jump;
    }

    setAction(action) {
        this.action = action;
    }
    getAction() {
        return this.action;
    }

    setRef(ref) {
        this.ref = ref;
    }

    getRef() {
        return this.ref;
    }

    setCommentFieldWidth(commentFieldWidth) {
        this.commentFieldWidth = commentFieldWidth;
    }
    getCommentFieldWidth() {
        return this.commentFieldWidth;
    }

    setCommentFieldMaxTextLenght(commentFieldMaxTextLenght) {
        this.commentFieldMaxTextLenght = commentFieldMaxTextLenght;
    }
    getCommentFieldMaxTextLenght(commentFieldMaxTextLenght) {
        return this.commentFieldMaxTextLenght;
    }

}

export class ProjectDescriptor {
    projectName = '';
    fields = [];
    endpoint = '';
    formTitle = '';

    deleteButtonVisible = true;
    newButtonVisible = true;
    filterButtonVisible = true;
    modifyButtonVisible = true;
    viewButtonVisible = false;
    viewXlsExportVisible = true;
    additionalButtons =[];
    
    formDimension = {width: 600, height: 600};

    constructor(projectName, endpoint, formTitle) {
        this.projectName = projectName;
        this.endpoint = endpoint;
        this.formTitle = formTitle;
    }

    getEndpoint() {
        return this.endpoint;
    }

    addFieldDescriptor(field) {
        this.fields.push(field);
    }

    getFieldDescriptors() {
        return this.fields;
    }

    getFormTitle() {
        return this.formTitle;
    }

    setFormDimensions(width, height) {
        this.formDimension = {width: width, height: height}
    }

    getFormDimensions() {
        return this.formDimension;
    }
    getProjectName() {
        return this.projectName;
    }

}



