import React from 'react';
import { FieldDescriptor } from './../utils.js';
import FormCombo from './../Components/FormCombo';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
//import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import DateEditor from './DateEditor';
//import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
//import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import ComboEditor from './ComboEditor';
//import BooleanEditor from './BooleanEditor';
import * as ajax from './../ajax';
import PagingToolbar from './PagingToolbar';
import DatePicker from "react-datepicker";
import TaxNumberField from "./TaxNumberField";
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import hu from 'date-fns/locale/hu';
//import calendar_ico from "./../img/white/edit_calendar_white_24dp.svg";
import DeleteIconWhite from './../img/white/delete_white_24dp.svg';
import AddWhiteIconWhite from './../img/white/add_white_24dp.svg';
//import FilterIconWhite from './../img/white/filter_alt_white_24dp.svg';
import EditIconWhite from './../img/white/edit_white_24dp.svg';
import DeleteIconBlack from './../img/black/delete_black_24dp.svg';
import AddWhiteIconBlack from './../img/black/add_black_24dp.svg';
//import FilterIconBlack from './../img/black/filter_alt_black_24dp.svg';
import EditIconBlack from './../img/black/edit_black_24dp.svg';
import personAddBlack from './../img/black/person_add_black_24dp.svg';
import personAddWhite from './../img/white/person_add_white_24dp.svg';
import saveBlack from './../img/black/save_black_24dp.svg';
import saveWhite from './../img/white/save_white_24dp.svg';
import "./../css/datePicker.css";
import CustomDate from './CustomDate';
import PopUpWindow from './PopUpWindow';
import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');
registerLocale('hu', hu);
class BasicForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeleteConfirmationDialog: false,
            initFocusOK: false,
            taxNumberFieldRef: React.createRef(),
            refs: {},
            columnOrder: [],
            lastInsertId: -1,
            prevTableData: null,
            action: 'nothing',
            gridRef: null,
            defaultUpdateBlocked: false,
            isFilterVisible: true,
            dateEditor: {
                active: false,
                id: null,
                key: null,
                value: null,
                editorProps: null
            },
            requestProps: null,
            skip: 0,
            loading: false,
            page: 1,
            selectedRow: -1,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 100,
                showingRowFrom: 0,
                showingRowTo: 5,
                totalRowCount: 0,
                skip: 0
            }
        }

        this.setRefs();
        this.state['formData'] = this.getData();
        this.tableColumns = this.getTableColumns();
        this.tableFilters = this.getTableFilters();
        this.endpoint = this.props.projectDescriptor.getEndpoint();
        this.layout = this.props.layout;
        this.componentCount = 0;
        this.setColumnOrder();
        if (typeof this.props.frameState != 'undefined') {
            this.props.frameState.instantUpdateForm = this.instantUpdateForm.bind(this);
        }

        this.insertProgressMask = false;
    }

    instantUpdateForm(partnerUniqueId) {
        //this.state.selectedRow = partnerUniqueId;
        this.setState({selectedRow: partnerUniqueId});
        this.loadFormData();
    }

    componentDidUpdate(prevProps) {

        if (this.state.initFocusOK === false) {
            if (this.state.taxNumberFieldRef != null) {
                if (typeof this.state.taxNumberFieldRef.current != undefined) {
                    try {
                        this.state.taxNumberFieldRef.current.focus();
                        //this.state.initFocusOK = true;
                        this.setState({initFocusOK: true});
                    } catch (e) {

                    }
                }
            }
        }
    }

    componentWillUnmount() {
        //this.state.initFocusOK = false;
        this.setState({initFocusOK: false});
    }

    setRefs = function () {
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            var ref = fieldDescriptor.getRef();
            if (ref != null) {
                this.state.refs[ref] = React.createRef();
            }
        }
    }

    getTableColumns = function () {
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        var tableColumns = [];
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            if (fieldDescriptor.isVisibleTableField()) {
                var tableColumn = this.getTableColumn(fieldDescriptor);
                tableColumns.push(tableColumn);
            }
        }
        return tableColumns;
    }

    getTableFilters = function () {
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        var tableFilters = [];
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            if (fieldDescriptor.getType() != FieldDescriptor.TYPE_ACTION_BUTTON) {
                var tableFilter = this.getTableFilter(fieldDescriptor);
                tableFilters.push(tableFilter);
            }
        }
        return tableFilters;
    }

    onColumnResize = function (resized) {
        var projectName = this.props.projectDescriptor.getProjectName();
        localStorage.setItem(projectName + '#' + resized.column.name, resized.width);
    }

    getColumnWidth = function (columnName, defaultSize) {
        var projectName = this.props.projectDescriptor.getProjectName();
        var storedSize = localStorage.getItem(projectName + "#" + columnName);
        return (storedSize != null) ? storedSize * 1.0 : defaultSize;
    }

    onColumnOrderChange = function (ordered) {
        var projectName = this.props.projectDescriptor.getProjectName();
        localStorage.setItem(projectName + '#customColumnOrder', ordered);
    }

    myColumnsReorder = function (columns) {
        var orderedColumns = [];
        var order = this.state.columnOrder;
        for (var o = 0; o < order.length; o++) {
            for (var c = 0; c < columns.length; c++) {
                var column = columns[c];
                if (order[o] == column.name) {
                    orderedColumns.push(column);
                }
            }
        }

        return orderedColumns;
    }

    setColumnOrder = function () {

        //this.state.columnOrder = [];
        this.setState({columnOrder: []});
        var invisibleFields = [];
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            this.state.columnOrder.push(fieldDescriptor.getName());
            if (fieldDescriptor.visibleTableField == false) {
                invisibleFields.push(fieldDescriptor.getName());
            }
        }

        // Stored
        var projectName = this.props.projectDescriptor.getProjectName();
        var storedOrder = localStorage.getItem(projectName + '#customColumnOrder');
        if (typeof storedOrder == 'undefined') {
            storedOrder = '';
        }

        if (storedOrder == null) {
            storedOrder = '';
        }
        if (storedOrder == '') {
            storedOrder = '';
        }

        var storedOrderArray = storedOrder.split(",");
        for (var ivf = 0; ivf < invisibleFields.length; ivf++) {
            storedOrderArray.push(invisibleFields[ivf]);
        }
        if (storedOrderArray.length == this.state.columnOrder.length) {
            //this.state.columnOrder = storedOrderArray;
            this.setState({columnOrder: storedOrderArray});
        }
    }

    getTableColumn = function (fieldDescriptor) {

        var formFieldType = fieldDescriptor.getType();
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        var defaultValue = fieldDescriptor.getDefaultValue();
        //var currentValue = this.state.formData[name];
        var editable = fieldDescriptor.isEditableTableField();
        switch (formFieldType) {
            case FieldDescriptor.TYPE_BOOLEAN:
                return this.getTableEditorBoolean(name, title, editable);

            case FieldDescriptor.TYPE_DATE:
                return this.getTableEditorDate(name, title, editable);

            case FieldDescriptor.TYPE_NUMBER:
                return this.getTableEditorNumber(name, title, editable);

            case FieldDescriptor.TYPE_SELECT:
                return this.getTableEditorSelect(name, title, defaultValue, editable);

            case FieldDescriptor.TYPE_SELECT_SEARCH:
                return this.getTableEditorText(name, title, defaultValue, editable);

            case FieldDescriptor.TYPE_TEXT:
                return this.getTableEditorText(name, title, editable);

            case FieldDescriptor.TYPE_COMMENT_FIELD:
                return this.getTableEditorText(name, title, editable);

            case FieldDescriptor.TYPE_LABEL:
                return this.getTableEditorLabel(name, title, editable);

            case FieldDescriptor.TYPE_FILE_UPLOADER:
                return this.getTableEditorText(name, title, editable);

            case FieldDescriptor.TYPE_ACTION_BUTTON:
                return this.getTableEditorActionButton(name, title, editable, fieldDescriptor);

            case FieldDescriptor.TYPE_TAX_NUMBER_FIELD:
                return this.getTableEditorText(name, title, editable, fieldDescriptor);

            default:
                break;
        }
        return null;
    }

    getTableFilter = function (fieldDescriptor) {

        var formFieldType = fieldDescriptor.getType();
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        var defaultValue = fieldDescriptor.getDefaultValue();
        //var currentValue = this.state.formData[name];
        switch (formFieldType) {
            case FieldDescriptor.TYPE_BOOLEAN:
                return this.getTableFilterBoolean(name, title);

            case FieldDescriptor.TYPE_DATE:
                return this.getTableFilterDate(name, title);

            case FieldDescriptor.TYPE_NUMBER:
                return this.getTableFilterNumber(name, title);

            case FieldDescriptor.TYPE_SELECT:
                return this.getTableFilterSelect(name, title, defaultValue);

            case FieldDescriptor.TYPE_COMMENT_FIELD:
                return this.getTableFilterSelect(name, title, defaultValue);

            case FieldDescriptor.TYPE_SELECT_SEARCH:
                return this.getTableFilterSelect(name, title, defaultValue);

            case FieldDescriptor.TYPE_TEXT:
                return this.getTableFilterText(name, title);

            case FieldDescriptor.TYPE_LABEL:
                return this.getTableFilterText(name, title, false);

            case FieldDescriptor.TYPE_FILE_UPLOADER:
                return this.getTableFilterText(name, title);

            case FieldDescriptor.TYPE_TAX_NUMBER_FIELD:
                return this.getTableFilterText(name, title);

            default:
                break;
        }
        return null;
    }

    // Editors


    getTableEditorBoolean = function (name, title, editable) {
        var booleanEditorStyleDark = {
            cursor: 'pointer',
            position: 'absolute',
            background: '#464d56',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            paddingLeft: 8,
            paddingTop: 9
        };
        var booleanEditorStyleLight = {
            cursor: 'pointer',
            position: 'absolute',
            background: '#FFFFFF',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            paddingLeft: 8,
            paddingTop: 9
        };
        var booleanEditorStyle = (this.props.blackTheme) ? booleanEditorStyleDark : booleanEditorStyleLight;
        return {
            name: name, header: title,
            filterEditor: BoolFilter,
            editable: editable,
            defaultWidth: this.getColumnWidth(name, 130),
            render: ({ value }) => {
                return (value == 0) ? "Nem" : "Igen";
            },
            renderEditor: (editorProps) => {
                var id = editorProps.cellProps.data.id;
                var key = editorProps.cellProps.id;
                return (
                        <div
                            key={'BooleanEditor_' + key + '_' + id}
                            tabIndex={0}
                            autoFocus
                            onClick={() => {
                                    var newValue = !editorProps.value;
                                    editorProps.onChange((newValue) ? 1 : 0);
                                    var value = (newValue) ? 1 : 0;
                                    this.updateRecord(id, key, value);
                                }}
                            onBlur={editorProps.onComplete}
                            onKeyDown={e => {
                                    if (e.key == 'Tab') {
                                        editorProps.onTabNavigation(
                                                true /*complete navigation?*/,
                                                e.shiftKey ? -1 : 1 /*backwards of forwards*/
                                                );
                                    }
                                }}
                            style={booleanEditorStyle}
                            >
                            {(editorProps.value) ? <span style={{color: '#ef9a9a'}}>Igen</span> : <span style={{color: '#ef9a9a'}}>Nem</span>}
                        </div>
                        );
            }
        }
    }

    dateToDotted = function (dateStr) {
        if (typeof dateStr == "undefined") {
            return '';
        }
        if (dateStr == null) {
            return '';
        }
        return dateStr.replaceAll("-", ".");
    }

    getTableEditorDate = function (name, title, editable) {
        return {
            name: name, header: title, editable: editable, defaultWidth: this.getColumnWidth(name, 150),
            dateFormat: 'YYYY-MM-DD',
            filterDelay: 1000,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'MM-DD-YYYY',
                    cancelButton: false,
                    highlightWeekends: false,
                    placeholder: index == 1 ? 'Előtte' : 'Utána'
            }
            },
            render: ({ value }) => {
                return <div>{this.dateToDotted(value)}</div>
            },
            editor: this.myDateEditor.bind(this)
        };
    }

    getTableEditorNumber = function (name, title, editable) {
        return {name: name, header: title, type: 'number', filterDelay: 1000, filterEditor: NumberFilter, editable: editable, defaultWidth: this.getColumnWidth(name, 150)};
    }

    getTableEditorSelect = function (name, title, defaultValue, editable) {

        var state = this.state;
        return {
            name: name, header: title, editable: editable, defaultWidth: this.getColumnWidth(name, 150),
            renderEditor: (editorProps) => {
                var id = editorProps.cellProps.data.id;
                var key = editorProps.cellProps.id;
                return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                    <ComboEditor
                        blackTheme={this.props.blackTheme}
                        callback={this.comboUpdateRecord.bind(this)}
                        values={defaultValue}
                        editorProps={editorProps}
                        state={state}
                        /></div>
            },
            render: ({ value }) => {
                return this.findLabelById(defaultValue, value);
            },
            filterEditor: SelectFilter,
            filterEditorProps: {
                multiple: true,
                wrapMultiple: false,
                dataSource: defaultValue.map(c => {
                    return {id: c.id, label: c.label}
                }),
            }
        };
    }

    getTableEditorText = function (name, title, editable) {
        return {name: name, header: title, editable: editable, filterDelay: 1000, defaultWidth: this.getColumnWidth(name, 200)};
    }

    getTableEditorActionButton = function (name, title, editable, fieldDescriptor) {
        var action = fieldDescriptor.getAction();
        return {
            name: name, header: title, editable: () => {
                return false;
            }, defaultWidth: this.getColumnWidth(name, 150),
            render: ({ value }) => {
                return <div key={'action_button_' + name + '_' + value}>
                <button
                    onClick={() => action(value)}
                    style={{
                                                backgroundColor: "#00000044",
                                                color: "white",
                                                width: '100%',
                                                height: '100%',
                                                cursor: 'pointer',
                                                border: 'solid 1px #aaaaaaaa'
                                            }}>{title}</button></div>;
            }
        };
    }

    getTableEditorLabel = function (name, title) {
        return {name: name, header: title, editable: () => false, defaultWidth: this.getColumnWidth(name, 200)};
    }

    // Filters 

    getTableFilterBoolean = function (name, title) {
        return {name: name, operator: 'eq', type: 'boolean', value: null};
    }
    getTableFilterDate = function (name, title) {
        return {name: name, operator: 'eq', type: 'date', value: ''};
    }
    getTableFilterNumber = function (name, title) {
        return {name: name, operator: 'eq', type: 'number'};
    }
    getTableFilterSelect = function (name, title, defaultValues) {
        return {name: name, operator: 'inlist', type: 'select'};
    }
    getTableFilterText = function (name, title) {
        return {name: name, operator: 'contains', type: 'string', value: ''};
    }

    getData = function () {
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        var val = {};
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            var name = fieldDescriptor.getName();
            var defaultValue = fieldDescriptor.getDefaultValue();
            val[name] = defaultValue;
            if (fieldDescriptor.getType() == FieldDescriptor.TYPE_FILE_UPLOADER) {
                this[name + "_ref"] = React.createRef();
            }
        }
        return val;
    }

    getTypeById = function (id) {
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            var name = fieldDescriptor.getName();
            if (name == id) {
                return fieldDescriptor.getType();
            }
        }
        return null;
    }

    setValue = function (e) {
        var id = e.target.id;
        var value = e.target.value;
        var type = this.getTypeById(id);
        if (type == FieldDescriptor.TYPE_BOOLEAN) {
            this.state.formData[id] = (e.target.checked) ? 1 : 0;
        } else {
            /**
             this.setState({formData: {
             ...this.state.formData,
             [id]: value
             }});
             * 
             */
            this.state.formData[id] = (value != null) ? value : '';
        }
        this.setState({});
    }

    getFormField = function (fieldDescriptor) {
        var isIdField = fieldDescriptor.isIDField();
        if (isIdField) {
            return this.getIdField(fieldDescriptor);
        }

        var formFieldType = fieldDescriptor.getType();
        switch (formFieldType) {
            case FieldDescriptor.TYPE_BOOLEAN:
                return this.getBooleanField(fieldDescriptor);

            case FieldDescriptor.TYPE_DATE:
                return this.getDateField(fieldDescriptor);

            case FieldDescriptor.TYPE_NUMBER:
                return this.getNumberField(fieldDescriptor);

            case FieldDescriptor.TYPE_SELECT:
                return this.getSelectField(fieldDescriptor);

            case FieldDescriptor.TYPE_SELECT_SEARCH:
                return this.getSelectSearchField(fieldDescriptor);

            case FieldDescriptor.TYPE_TEXT:
                return this.getTextField(fieldDescriptor);

            case FieldDescriptor.TYPE_COMMENT_FIELD:
                return this.getCommentField(fieldDescriptor);

            case FieldDescriptor.TYPE_LABEL:
                return this.getLabelField(fieldDescriptor);

            case FieldDescriptor.TYPE_FILE_UPLOADER:
                return this.getFileUploaderField(fieldDescriptor);

            case FieldDescriptor.TYPE_TAX_NUMBER_FIELD:
                return this.getTaxNumberField(fieldDescriptor);

            default:
                break;
        }
    }

    getIdField = function (fieldDescriptor) {
        var name = fieldDescriptor.getName();
        //var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var currentValue = this.state.formData[name];
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>Azonosító</div>,
            <div className={"titleSmall"} style={{position: 'absolute', top: componentPos.y, left: componentPos.x, marginTop: '5px'}}>{currentValue}</div>,
        ];
    }

    getBooleanField = function (fieldDescriptor) {
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var currentValue = this.state.formData[name];
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <input key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x, minHeight: 24}} checked={currentValue} onChange={this.setValue.bind(this)} type="checkbox" id={name} />
        ];
    }

    getDateField = function (fieldDescriptor) {
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        //var currentValue = this.state.formData[name];
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                <CustomDate
                    dataKey={name}
                    reload={this.setFormDate.bind(this)}
                    currentValue={this.state.formData[name]}
                    writeBack={this.state.formData}
                    blackTheme={this.props.blackTheme}
                    />
            </div>]
    }

    getNumberField = function (fieldDescriptor) {

        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var currentValue = this.state.formData[name];
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        var formInputClass = (this.props.blackTheme) ? "formInputDark" : "formInputLight";
        /*return [
         <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px', marginTop: '5px'}}>{title}</div>,
         <div  key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
         <input  className={formInputClass} value={currentValue} onChange={this.setValue.bind(this)} type="number" id={name}  value={defaultValue} />
         </div>
         ];*/
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                <input className={formInputClass} value={currentValue} onChange={this.setValue.bind(this)} type="number" id={name} />
            </div>
        ];
    }

    getSelectField = function (fieldDescriptor) {
        //var isIdField = fieldDescriptor.isIDField();
        //var type = fieldDescriptor.getType();
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        var defaultValue = fieldDescriptor.getDefaultValue();
        var currentValue = this.state.formData[name];
        var options = [];
        for (var i = 0; i < defaultValue.length; i++) {
            var option = defaultValue[i];
            options.push(<option key={name + '_' + option.id} value={option.id} >{option.label}</option>);
        }

        //var currentValue = this.state.formData[name];
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        var comboInputClass = (this.props.blackTheme) ? "comboInputDark" : "comboInputLight";
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                <select className={comboInputClass} onChange={this.setValue.bind(this)} id={name} value={currentValue}>{options}</select>
            </div>
        ];
    }

    getSelectSearchField = function (fieldDescriptor) {
        //var isIdField = fieldDescriptor.isIDField();
        //var type = fieldDescriptor.getType();
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        //var currentValue = this.state.formData[name];
        var endPoint = fieldDescriptor.getEndPoint();
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                <FormCombo
                    style={{minWidth: '400px'}}
                    formComboRef={this.state.formData[name]}
                    dataSourceUrl={endPoint}
                    afterSelect={() => {
                        }}
                    />
            </div>
        ];
    }
    getTextField = function (fieldDescriptor) {

        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var currentValue = this.state.formData[name];
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        var formInputClass = (this.props.blackTheme) ? "formInputDark" : "formInputLight";
        var refKey = fieldDescriptor.getRef();
        var ref = null;
        if (refKey != null) {
            if (typeof this.state.refs[refKey] != 'undefined' && this.state.refs[refKey] != null) {
                ref = this.state.refs[refKey]
            }
        }

        var input = null;
        if (ref != null) {
            input = <input autoFocus ref={ref} className={formInputClass} value={currentValue} type="text" onChange={this.setValue.bind(this)} id={name} />
        } else {
            input = <input className={formInputClass} value={currentValue} type="text" onChange={this.setValue.bind(this)} id={name} />
        }


        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                {input}
            </div>
        ];
    }

    getCommentField = function (fieldDescriptor) {

        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var currentValue = this.state.formData[name];
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        var formInputClass = (this.props.blackTheme) ? "formInputDark" : "formInputLight";
        var refKey = fieldDescriptor.getRef();
        var commentFieldWidth = fieldDescriptor.getCommentFieldWidth();
        var commentFieldMaxTextLenght = fieldDescriptor.getCommentFieldMaxTextLenght();

        if (currentValue != null && commentFieldMaxTextLenght > -1) {
            if (currentValue.length > commentFieldMaxTextLenght) {
                currentValue = currentValue.slice(0, commentFieldMaxTextLenght);
            }
        }


        var ref = null;
        if (refKey != null) {
            if (typeof this.state.refs[refKey] != 'undefined' && this.state.refs[refKey] != null) {
                ref = this.state.refs[refKey]
            }
        }



        var input = null;
        if (ref != null) {
            input = <input style={{minWidth: commentFieldWidth}} autoFocus ref={ref} className={formInputClass} value={currentValue} type="text" onChange={this.setValue.bind(this)} id={name} />
        } else {
            input = <input style={{minWidth: commentFieldWidth}} className={formInputClass} value={currentValue} type="text" onChange={this.setValue.bind(this)} id={name} />
        }


        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x, minWidth: commentFieldWidth, maxWidth: commentFieldWidth}}>
                {input}
            </div>
        ];
    }

    getLabelField = function (fieldDescriptor) {

        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var currentValue = this.state.formData[name];
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        var formInputClass = (this.props.blackTheme) ? "formInputDark" : "formInputLight";
        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                <input disabled={true} className={formInputClass} value={currentValue} type="text" onChange={this.setValue.bind(this)} id={name} />
            </div>
        ];
    }

    getFileUploaderField = function (fieldDescriptor) {
        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        //var currentValue = this.state.formData[name];
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        return [
            <div style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}} className={"titleSmall"}>{title}:</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}} >
                <input
                    name={name}
                    ref={this[name + "_ref"]}
                    onChange={this.updloadPDF.bind(this)}
                    type="file"
                    style={{maxWidth: '0px', maxHeight: '0px'}}
                    />
                <div className={"titleSmall"} style={{position: 'absolute', top: 0, left: 0, width: 441, height: 40}}>
                    <button
                        style={{minHeight: '20px', marginRight: '10px', marginTop: '5px'}}
                        className={"bookingFormButton titleSmall"}
                        onClick={() => {
                                this[name + "_ref"].current.click();
                            }}>Fájl kiválasztása</button>
                    {this.state.formData[name]['filename']}
                </div>
            </div>
        ];
    }

    getTaxNumberField = function (fieldDescriptor) {

        var name = fieldDescriptor.getName();
        var title = fieldDescriptor.getTitle();
        //var defaultValue = fieldDescriptor.getDefaultValue();
        var currentValue = this.state.formData[name];
        var jump = fieldDescriptor.getJump();
        this.componentCount += jump;
        var labelPos = this.layout.getComponentPosition(this.componentCount++);
        var componentPos = this.layout.getComponentPosition(this.componentCount++);
        var formInputClass = (this.props.blackTheme) ? "formInputDark" : "formInputLight";

        return [
            <div className={"titleSmall"} style={{position: 'absolute', top: labelPos.y, left: labelPos.x, marginTop: '5px'}}>{title}</div>,
            <div key={"key_" + name} style={{position: 'absolute', top: componentPos.y, left: componentPos.x}}>
                <TaxNumberField
                    taxNumberFieldRef={this.state.taxNumberFieldRef}
                    state={this.state.formData}
                    refs={this.state.refs}
                    className={formInputClass}
                    value={currentValue} type="text"
                    onChange={this.setValue.bind(this)}
                    id={name} />
            </div>
        ];
    }

    getForm = function () {
        var projectDescriptor = this.props.projectDescriptor;
        var fieldDescriptors = projectDescriptor.getFieldDescriptors();
        var formFields = [];
        for (var i = 0; i < fieldDescriptors.length; i++) {
            var fieldDescriptor = fieldDescriptors[i];
            var isVisibleFormField = fieldDescriptor.isVisibleFormField();
            if (isVisibleFormField) {
                var formField = this.getFormField(fieldDescriptor);
                formFields.push(formField);
            }
        }
        this.componentCount = 0;
        return <div style={{margin: '5px', position: 'relative'}}>{formFields}</div>;
    }

    setFormDate = function (key, value) {
        this.state.formData[key] = value;
    }

    reload = function (skip) {
        if (this.state.dateEditor.editorProps) {
            this.state.dateEditor.editorProps.onComplete();
        }
        this.state.paging.skip = skip;
        this.setState({
            dateEditor: {
                active: this.state.dateEditor.active,
                id: null,
                key: null,
                value: null,
                editorProps: null,
                defaultUpdateBlocked: false
            }
        });
    }

    onSelectionChange = function (selection) {
        this.state.defaultUpdateBlocked = true;
        this.state.selectedRow = selection.data.id;
        return true;
    }

    deleteRecord = function () {
        if (this.state.selectedRow == -1) {
            return false;
        }



        try {
            const url = this.endpoint + "delete.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.state.action = 'nothing';
                        this.state.lastInsertId = -1;
                        this.state.selectedRow = this.suggestSelection(this.state.selectedRow);
                        this.reload(this.state.paging.skip);
                        this.setState({showDeleteConfirmationDialog: false});

                    }).catch(jsonError => {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    insertRecord = function () {
        this.insertProgressMask = true;
        this.setState({});
        try {
            const url = this.endpoint + "insert.php";
            return fetch(url, {
                method: "POST",
                //credentials: "cors",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.insertProgressMask = false;
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.state.lastInsertId = response.data.lastInsertId;
                            this.state.selectedRow = response.data.lastInsertId;
                            this.reload(this.state.paging.skip);
                            this.openInsertForm(this.state.requestProps)
                        }
                    }).catch(jsonError => {
                        this.insertProgressMask = false;
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    this.insertProgressMask = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                this.insertProgressMask = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            this.insertProgressMask = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    comboUpdateRecord = function (id, key, value) {
        this.state.defaultUpdateBlocked = true;
        this.updateRecord(id, key, value);
    }

    updateRecord = function (id, key, value) {
        try {
            const url = this.endpoint + "update.php";
            var reload = this.reload.bind(this);
            var skip = this.state.paging.skip;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, key: key, value: value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                        reload(skip);
                    }).catch(jsonError => {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    exportToXls = function () {

        var state = this.state;
        var skip = this.state.requestProps.skip;
        var limit = this.state.requestProps.limit;
        var sortInfo = this.state.requestProps.sortInfo;
        var filterValue = this.state.requestProps.filterValue;

        try {
            const url = this.endpoint + "xls.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;
                        window.open(data.file, '_blank');
                    }).catch(jsonError => {
                        this.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    this.state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            this.state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    customPaging = function () {
        return <PagingToolbar blackTheme={this.props.blackTheme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }

    showCalendar = function (editorProps) {

        this.setState({
            dateEditor: {
                active: true,
                id: editorProps.cell.props.data.id,
                key: editorProps.cell.props.id,
                editorProps: editorProps,
                value: null
            }
        });
    }

    editingEnd = function () {
        this.state.defaultUpdateBlocked = false;
        this.state.dateEditor.editorProps.onComplete();
    }

    myDateEditor = function (editorProps) {
        return <DateEditor
            blackTheme={this.props.blackTheme}
            editorState={this.state.dateEditor}
            editorProps={editorProps}
            showCalendar={this.showCalendar.bind(this)}
            updateRecord={this.updateRecord.bind(this)}
            state={this.state}
            />
    }

    dateRendererClick = function (data) {
        this.state.dateEditor.value = data;
        this.state.defaultUpdateBlocked = true;
    }

    calendarCancel = function (e) {
        this.state.defaultUpdateBlocked = true;
        if (this.state.dateEditor.editorProps) {
            this.state.dateEditor.editorProps.onComplete();
        }
        this.state.dateEditor.active = false;
        this.setState({});
    }

    setFilterVisible = function () {
        this.setState({isFilterVisible: !this.state.isFilterVisible});
    }

    findLabelById = function (array, id) {

        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            if (obj.id == id) {
                return obj.label;
            }
        }
        return "";
    }

    setGridRef = function (gridRef) {
        this.state.gridRef = gridRef;
    }

    onCellSelectionChanged = function (cell) {
        if (typeof cell == 'undefined') {
            return false;
        }
        if (cell == null) {
            return false;
        }
        var keys = Object.keys(cell);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (typeof key != 'undefined' && key != null) {
                var idColName = key.split(',');
                if (idColName.length == 2) {
                    var id = idColName[0];
                    this.state.selectedRow = id;
                }
            }
        }
    }

    isSelectedRecordExists = function (data, id) {
        if (typeof data == 'undefined') {
            return false;
        }

        if (typeof data.length == 'undefined') {
            return false;
        }
        if (data.length == 0) {
            return false;
        }

        if (typeof id == 'undefined' || id == null || id == -1) {
            return false;
        }

        for (var i = 0; i < data.length; i++) {
            var record = data[i];
            if (record.id == id) {
                return true;
            }
        }
        return false;
    }

    getPositionById = function (id) {
        if (typeof this.state.prevTableData == 'undefined') {
            return -1;
        }

        if (this.state.prevTableData == null) {
            return -1;
        }

        if (typeof this.state.prevTableData.data == 'undefined') {
            return -1;
        }
        if (this.state.prevTableData.data.length == 0) {
            return -1;
        }

        var prevTableData = this.state.prevTableData.data;
        for (var i = 0; i < prevTableData.length; i++) {
            if (prevTableData[i].id == id) {
                return i * 1;
            }
        }
    }

    getIdByPosition = function (pos) {

        if (typeof this.state.prevTableData == 'undefined') {
            return -1;
        }

        if (this.state.prevTableData == null) {
            return -1;
        }

        if (typeof this.state.prevTableData.data == 'undefined') {
            return -1;
        }
        if (this.state.prevTableData.data.length == 0) {
            return -1;
        }

        if (pos > this.state.prevTableData.data.length) {
            return -1;
        }
        if (pos < 0) {
            return -1;
        }

        var prevTableData = this.state.prevTableData.data;
        for (var i = 0; i < prevTableData.length; i++) {
            if (pos == i) {
                return prevTableData[i].id;
            }
        }
    }

    suggestSelection = function (id) {
        var intId = id * 1;
        var deletedPosition = this.getPositionById(intId);
        var prevId = this.getIdByPosition(deletedPosition - 1);
        var nextId = this.getIdByPosition(deletedPosition + 1);
        if (typeof nextId != 'undefined' && nextId != -1) {
            return nextId;
        }
        if (typeof prevId != 'undefined' && prevId != -1) {
            return prevId;
        }
        return -1;
    }

    openInsertForm = function () {
        this.state.initFocusOK = false;
        this.loadFormData();
    }

    openUpdateForm = function () {
        this.state.initFocusOK = false;
        this.state.lastInsertId = -1;
        this.loadFormData();
    }

    loadFormData = function () {
        if (this.state.selectedRow == -1) {
            return false;
        }
        try {
            const url = this.endpoint + "formData.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                        this.state.action = 'update';
                        this.state.formData = response.data;
                        this.reload(this.state.paging.skip);

                    }).catch(jsonError => {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    updateForm = function () {

        if (this.state.selectedRow == -1) {
            return false;
        }
        try {
            const url = this.endpoint + "updateForm.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({data: this.state.formData})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.state.action = 'nothing';
                            this.reload(this.state.paging.skip);
                        }
                    }).catch(jsonError => {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    getBase64 = function (file, fieldName) {
        var reader = new FileReader();
        var self = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
            self.state.formData[fieldName]['file'] = reader.result;
            self.setState({});
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    updloadPDF = function (e) {
        var fieldName = e.target.name;
        var files = e.target.files;
        var file = files[0];
        this.state.formData[fieldName]["filename"] = file['name'];
        this.getBase64(file, fieldName);
    }

    showDeleteConfirmation = function () {
        if (this.state.selectedRow != -1) {
            this.setState({showDeleteConfirmationDialog: true});
        } else {
            this.props.alertDialog("Kérem jelölje ki azt a sort a táblázatban, amit törölni szeretne.");
        }
    }
    cancelDeleteConfirmation = function () {
        this.setState({showDeleteConfirmationDialog: false});
    }

    getAdditionalButtons = function () {
        if (typeof this.props.projectDescriptor == 'undefined') {
            return [];
        }
        if (this.props.projectDescriptor == null) {
            return [];
        }
        if (typeof this.props.projectDescriptor.additionalButtons == "undefinded") {
            return [];
        }
        if (this.props.projectDescriptor.additionalButtons == null) {
            return [];
        }
        return this.props.projectDescriptor.additionalButtons;
    }

    render() {

        const gridStyle = {minHeight: window.innerHeight - 180, fontFamily: 'Arial'};
        var state = this.state;
        const columns = this.tableColumns;
        const data = [];
        var updateRecord = this.updateRecord.bind(this);
        //var state = this.state;
        const callback = function (result, data) {
            if (state.defaultUpdateBlocked == false) {
                updateRecord(result.data.id, result.columnId, result.value);
                result.data[result.columnId] = result.value;
            }

            return data;
        }

        //var err = this.state.paging.skip;
        const filterValue = this.tableFilters;
        const dataLoader = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
            if (this.state.action == 'update' && this.state.prevTableData != 'undefined') {
                return Promise.resolve(this.state.prevTableData);
            }
            var state = this.state;
            this.state.requestProps = {skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
            try {
                const url = this.endpoint + "select.php";
                state.update = false;
                return fetch(url, {
                    method: "POST",
                    //credentials: "include",
                    //credentials: "*", // Tesztüzemben
                    headers: new Headers({"Content-Type": "application/json"}),
                    body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
                }).then(response => {

                    if (response.ok) {

                        return response.json().then(response => {

                            this.state.defaultUpdateBlocked = false;
                            var totalCount = response.count;
                            var data = response.data;
                            state.paging.totalRowCount = totalCount;
                            var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                            state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                            state.paging.showingRowFrom = skip;
                            state.paging.showingRowTo = skip + state.pageRowCount;
                            var isSelectedRecordExists = this.isSelectedRecordExists(data, this.state.selectedRow);
                            if (isSelectedRecordExists == false) {
                                if (data.length > 0) {
                                    this.state.selectedRow = data[0].id;
                                } else {
                                    this.state.selectedRow = -1;
                                }
                            }
                            this.state.prevTableData = {data, count: parseInt(totalCount), skip: skip};
                            return Promise.resolve({data, count: parseInt(totalCount), skip: skip});
                        }).catch(jsonError => {
                            this.state.defaultUpdateBlocked = false;
                            if (null == jsonError) {

                                console.error("Fetch JSON error:");
                            } else {
                                console.error("Fetch JSON error:" + String(jsonError));
                            }
                        });
                    } else {
                        this.state.defaultUpdateBlocked = false;
                        console.error("HTTP status error:", response.statusText);
                    }
                }).catch(error => {
                    this.state.defaultUpdateBlocked = false;
                    console.error("Fetch error:", error);
                    if (null == error) {
                        console.error("Fetch error:");
                    } else {
                        console.error("Fetch error:" + String(error));
                    }
                });
            } catch (ex) {
                this.state.defaultUpdateBlocked = false;
                console.error("Fetch exception:", String(ex));
        }
        }


        // Ellenőrizni kell, hogy ez kell-e?
        const setSkip = function () {
            this.setState({skip: this.state.skip + 10});
        }


        const renderPaginationToolbar = this.customPaging.bind(this);
        var isVisibleDateEditor = (this.state.dateEditor.active) ? 'grid' : 'none';
        var filters = [];
        if (this.state.isFilterVisible) {
            filters = filterValue;
        }

        const DEFAULT_ACTIVE_CELL = [0, 1];
        let inEdit;
        const onEditStart = () => {
            inEdit = true
        }

        const onEditStop = () => {
            requestAnimationFrame(() => {
                inEdit = false;
                this.state.defaultUpdateBlocked = true;
                this.state.gridRef.current.focus();
            })
        }

        const onKeyDown = (event) => {
            if (inEdit) {
                return
            }
            const grid = this.state.gridRef.current;
            let [rowIndex, colIndex] = grid.computedActiveCell;
            if (typeof grid.data[rowIndex] != 'undefined') {
                var selectedRowId = grid.data[rowIndex].id;
                this.state.selectedRow = selectedRowId;
            }


            if (event.key === 'Enter') {
                const column = grid.getColumnBy(colIndex)
                grid.startEdit({columnId: column.name, rowIndex})
                event.preventDefault()
                return;
            }
            if (event.key !== 'Tab') {
                return
            }
            event.preventDefault()
            event.stopPropagation()

            const direction = event.shiftKey ? -1 : 1

            const columns = grid.visibleColumns
            const rowCount = grid.count

            colIndex += direction
            if (colIndex === -1) {
                colIndex = columns.length - 1
                rowIndex -= 1
            }
            if (colIndex === columns.length) {
                rowIndex += 1
                colIndex = 0
            }
            if (rowIndex < 0 || rowIndex === rowCount) {
                return
            }

            grid.setActiveCell([rowIndex, colIndex])
        }

        const onEditComplete = ({ value, columnId, rowIndex }) => {
            data[rowIndex] = Object.assign({}, data[rowIndex], {[columnId]: value})
        }



        var basicForm = null;
        if (this.state.action == 'update') {
            var formDimensions = this.props.projectDescriptor.getFormDimensions();
            var isNewInsertAborted = this.state.selectedRow == this.state.lastInsertId;
            var abortClick = null;
            var formType = (isNewInsertAborted) ? "Létrehozás" : "Módosítás";
            var buttonTitleAbort = (isNewInsertAborted) ? "Mégsem" : "Kilépés";
            if (isNewInsertAborted) {
                abortClick = this.deleteRecord.bind(this);
            } else {
                abortClick = () => {
                    this.setState({action: 'nothing'})
                };
            }

            basicForm = <PopUpWindow blackTheme={this.props.blackTheme} style={{position: 'relative', width: formDimensions.width, height: formDimensions.height, overflow: 'visible'}}>
                <div style={{textAlign: 'center'}}><h3 style={{margin: '0px', padding: '0px'}}>{this.props.projectDescriptor.getFormTitle()}</h3></div>
                <div style={{textAlign: 'center'}}><h5 style={{margin: '0px', padding: '0px'}}>{formType}</h5></div>
                {this.getForm()}
                <div style={{position: 'absolute', bottom: 30, width: '100%', textAlign: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <button style={{backgroundColor: '#9FD027'}} className={"bookingFormButton"} onClick={this.updateForm.bind(this)}>Rögzítés</button>&nbsp;
                        <button className={"bookingFormButton"} onClick={abortClick.bind(this)} >{buttonTitleAbort}</button>
                    </div>
                </div>
            </PopUpWindow>;
        }



        var deleteButton = "";
        var newButton = "";
        var filterButton = "";
        var modifyButton = "";
        var viewButton = "";
        var companyInfoButton = "";
        var exportToXlsButton = "";


        var darkButtonStyle = {
            backgroundColor: '#475360',
            color: '#FFFFFFaa',
            border: 'solid 0px #FFFFFF',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000',
            cursor: 'pointer'
        };
        var lightButtonStyle = {

            backgroundColor: '#FFFFFF',
            color: '#000000aa',
            border: 'solid 0px #000000',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000aa',
            cursor: 'pointer'

        };
        var buttonStyle = (this.props.blackTheme) ? darkButtonStyle : lightButtonStyle;
        if (this.props.projectDescriptor.deleteButtonVisible) {
            deleteButton = <button style={buttonStyle} onClick={this.showDeleteConfirmation.bind(this)} >
                <img className={"buttonImage"} src={(this.props.blackTheme) ? DeleteIconWhite : DeleteIconBlack} alt='Törlés ikon' />
                Törlés
            </button>;
        }
        if (this.props.projectDescriptor.newButtonVisible) {
            newButton = <button style={buttonStyle} onClick={this.insertRecord.bind(this)} >
                <img className={"buttonImage"} src={(this.props.blackTheme) ? AddWhiteIconWhite : AddWhiteIconBlack} alt='Új tétel ikon' />
                Új tétel
            </button>;
        }
        if (this.props.projectDescriptor.filterButtonVisible) {
            //filterButton = <button className={"buttonStyle"} onClick={this.setFilterVisible.bind(this)} ><img className={"buttonImage"} src={FilterIcon}/>Filter</button>;
        }
        if (this.props.projectDescriptor.modifyButtonVisible) {
            modifyButton = <button style={buttonStyle} onClick={() => {
                                this.openUpdateForm(this.state.requestProps)
                                    }} >
                <img className={"buttonImage"} src={(this.props.blackTheme) ? EditIconWhite : EditIconBlack} alt='Módosítás ikon' />
                Módosítás
            </button>;
        }

        if (this.props.companyInfoButtonVisible) {
            var title = (this.props.isPartner) ? "Partner hozzáadása" : "Cég hozzáadása";
            companyInfoButton = <button style={buttonStyle} onClick={() => {
                                this.props.companyBackgroundInfoPanelOpen('', '', '', this.props.isCompany, this.props.isPartner);
                                    }} >
                <img className={"buttonImage"} src={(this.props.blackTheme) ? personAddWhite : personAddBlack} alt='Partner vagy cég hozzáadása ikon' />
                {title}
            </button>;
        }

        if (this.props.viewXlsExportVisible) {
            exportToXlsButton = <button style={buttonStyle} onClick={() => {
                                this.exportToXls();
                                    }} >
                <img className={"buttonImage"} src={(this.props.blackTheme) ? saveWhite : saveBlack} alt='Exportálás ikon' />
                Exportlálás
            </button>;
        }

        if (this.props.projectDescriptor.viewButtonVisible) {
            viewButton = <button style={buttonStyle} onClick={() => {
                                this.openUpdateForm(this.state.requestProps)
                                    }} >Megtekintés</button>;
        }

        var gridTheme = "";
        if (this.props.blackTheme) {
            gridTheme = "default-dark";
        } else {
            gridTheme = "default-light";
        }


        var deleteConfirmationDialog = "";
        if (this.state.showDeleteConfirmationDialog == true) {
            if (this.props.projectDescriptor.getProjectName() == "CompanyManager") {
                deleteConfirmationDialog = <DeleteConfirmationDialog
                    size={"big"}
                    delete={this.deleteRecord.bind(this)}
                    cancel={this.cancelDeleteConfirmation.bind(this)}
                    >
                    <b><font color="red">FIGYELEM!</font></b><br /> A teljes adatbázis törlődni fog. Cég törléskor törlődenek a hozzá kapcsolódó tételek az adatbázisból.
                    Valóban törölni szeretné az <b>{this.state.selectedRow}</b> azonosítóval nyilvántartott céget?
                </DeleteConfirmationDialog>
            } else {
                deleteConfirmationDialog = <DeleteConfirmationDialog
                    delete={this.deleteRecord.bind(this)}
                    cancel={this.cancelDeleteConfirmation.bind(this)}
                    >
                    Valóban törölni szeretné az <b>{this.state.selectedRow}</b> azonosítóval nyilvántartott tételt?
                </DeleteConfirmationDialog>
            }

        }


        var reactDatepickerClass = (this.props.blackTheme) ? "react-datepickerDark" : "react-datepickerLight";
        var calendarClose = (this.props.blackTheme) ? "calendarCloseDark" : "calendarCloseLight";
        var panelBackground = (this.props.blackTheme) ? "#313943" : "#FFFFFF";

        if (typeof this.props.insertProgressMask != 'undefined' && this.props.insertProgressMask == true) {
            if (this.insertProgressMask == true) {
                const url = ajax.getUrlBase() + "img/progress.gif";
                return <PopUpWindow style={{textAlign: 'center', maxWidth: 800, maxHeight: 650}} >
                    <div>AZ IRODA LÉTREHOZÁSA FOLYAMATBAN. KÉREM VÁRJON.</div>
                    <img style={{alignContent: 'center'}} src={url} alt='Folyamatjelző GIF' />
                </PopUpWindow>
            }
        }

        var additionalButtons = this.getAdditionalButtons();

        return (
                <div style={{margin: 'auto', backgroundColor: panelBackground}}>
                
                    {basicForm}
                
                    <div onClick={this.calendarCancel.bind(this)} style={{display: isVisibleDateEditor}} className={"loadWrapper"} ></div>
                    <div style={{display: 'flex', justifyContent: 'center', maxHeight: '0px'}}>
                        <div style={{display: isVisibleDateEditor, position: 'sticky', top: 30, left: 30, backgroundColor: 'black', zIndex: '1000'}}>
                            <DatePicker
                                onChange={() => {
                                        this.state.defaultUpdateBlocked = true;
                                        this.state.dateEditor.active = false;
                                        var date = new Date();
                                        var dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                                        this.state.dateEditor.value = dateString;
                                        this.updateRecord(this.state.dateEditor.id, this.state.dateEditor.key, dateString);
                                    }}
                
                                locale="hu"
                
                                calendarClassName={reactDatepickerClass}
                                inline />
                            <button onClick={this.calendarCancel.bind(this)} className={calendarClose} >Mégsem</button>
                        </div>
                    </div>
                
                    {deleteButton}
                    {newButton}
                    {filterButton}
                    {modifyButton}
                    {viewButton}
                    {companyInfoButton}
                    {exportToXlsButton}
                    {additionalButtons}
                
                
                    <ReactDataGrid
                        handle={this.setGridRef.bind(this)}
                        idProperty="id"
                        columns={this.myColumnsReorder(columns)}
                        style={gridStyle}
                        theme={gridTheme}
                
                        defaultActiveCell={DEFAULT_ACTIVE_CELL}
                        onKeyDown={onKeyDown}
                        onEditComplete={callback}
                        onEditStart={onEditStart}
                        onEditStop={onEditStop}
                        onCellSelectionChange={this.onCellSelectionChanged.bind(this)}
                        editable={true}
                
                
                        i18n={ajax.getI18n()}
                        dataSource={dataLoader}
                        defaultLimit={100}
                        defaultFilterValue={filters}
                
                
                        loading={this.state.action == 'update'}
                        pagination={true}
                        renderPaginationToolbar={renderPaginationToolbar}
                        skip={this.state.paging.skip}
                        limit={this.state.paging.pageRowCount}
                        defaultCellSelection={[]}
                        onColumnResize={this.onColumnResize.bind(this)}
                
                        onColumnOrderChange={this.onColumnOrderChange.bind(this)}
                
                        enableFiltering={this.state.isFilterVisible}
                        multiSelect={false}
                        onFilterValueChange={() => {
                                this.state.paging.skip = 0;
                                this.state.paging.currentPage = 0;
                                this.setState({});
                            }}
                        />
                    {deleteConfirmationDialog}
                </div>
                );
    }
}

export default BasicForm;