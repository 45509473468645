// A connect ben zárójelezni kell a $currentYear = 2022; változót
// "homepage": "https://www.devszerver.hu/docs/",
// "homepage": "http://localhost/docs/build",
// "homepage": "/teszt_front",
// "homepage": "http://localhost/",
// "homepage": "https://ugyfelkapu.qs.hu/", //-- EZ KELL ÉLESBEN



export function getUrlBase() {
  // return "http://localhost:80/docs_back/"; 
   return "/docs_back/";
    // return "/teszt_back/";
}

export function getI18n() {
    return {
        pageText: 'Lap ',
        ofText: ' Összesen ',
        perPageText: 'Sorok száma',
        showingText: 'Showing ',
        clearAll: 'Összes törlése',
        clear: 'Törlés',
        showFilteringRow: 'Szűrés megjelenítése',
        hideFilteringRow: 'Szűrés elrejtése',
        enable: 'Engedélyezve',
        disable: 'Tiltva',
        sortAsc: 'Növekvő rendezés', //'Sort ascending',
        sortDesc: 'Csökkenő rendezés', //'Sort descending',
        unsort: 'Rendezetlen',
        group: 'Csoportosít',
        ungroup: 'Csoport bontása',
        lockStart: 'Rögzítés kezdete',
        lockEnd: 'Rögzítés vége',
        unlock: 'Rögzítés megszüntetése',
        columns: 'Oszlopok',
        contains: 'Tartalmazza', // 'Contains',
        startsWith: 'Elejétől',
        endsWith: 'Végétől',
        notContains: 'Nem tartalmazza',
        inlist: 'Listában',
        notinlist: 'Nincs a listában',
        neq: 'Nem egyenlő',
        inrange: 'Intervallumban',
        notinrange: 'Intervallumon kívül',
        eq: 'Egyenlő',
        notEmpty: 'Nem üres',
        empty: 'Üres',
        lt: 'Kisebb',
        lte: 'Kisebb vagy egyenlő',
        gt: 'Nagyobb',
        gte: 'Nagyobb vagy egyenlő',
        before: 'Előtte',
        beforeOrOn: 'Előtte inkluzív',
        afterOrOn: 'Utána inkluzív',
        after: 'Utána',
        start: 'Kezdés',
        end: 'Befejezés',
        dragHeaderToGroup: 'Húzza a fejlécre a csoportosításhoz',
        noRecords: 'Nincs találat',
        'calendar.todayButtonText': 'Mai nap',
        'calendar.clearButtonText': 'Törlés',
        'calendar.okButtonText': 'OK',
        'calendar.cancelButtonText': 'Mégsem'
    }
}
